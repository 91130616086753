.intro_pane{
    height: 100vh;
    padding-bottom: 3rem;
    background: linear-gradient(to right, #080b53,#0c0f6b);
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.intro_pane_greetings{
    font-size: 5rem;
    font-weight: 600;
    color: #fff;
    text-align: center;
    margin: 0;
    padding: 0;
}

.scroll-downs {
    position: absolute;
    top: 1;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width :34px;
    height: 55px;
    padding-bottom: 8rem;
}

.mousey {
    width: 3px;
    padding: 10px 15px;
    height: 35px;
    border: 2px solid #fff;
    border-radius: 25px;
    opacity: 0.75;
    box-sizing: content-box;
}

.scroller {
    width: 3px;
    height: 10px;
    border-radius: 25%;
    background-color: #fff;
    animation-name: scroll;
    animation-duration: 2.2s;
    animation-timing-function: cubic-bezier(.15,.41,.69,.94);
    animation-iteration-count: infinite;
}
@keyframes scroll {
    0% { opacity: 0; }
    10% { transform: translateY(0); opacity: 1; }
    100% { transform: translateY(15px); opacity: 0;}
}
