.contacts{
    background-color: #CCA43B;
    font-family: "Outfit", sans-serif;
    padding: 7rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: black;
    /* height:70vh; */
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    min-height: fit-content;
}

.contacts_header h1{
    border-bottom: 4px solid white;
    border-radius: 10%;
    padding-bottom: 0.4rem;
    margin-bottom: 1rem;
    font-weight: bold;
    font-size: 2.5rem;
}

form {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    border: 1px solid #ddd;
    border-radius: 3rem;
}

label {
    margin-bottom: 10px;
}

input,
textarea {
    width: 100%;
    padding: 10px;
    margin: 5px 0;
    border: none;
    border-bottom: 1px solid #ddd; /* Add an underline effect */
    outline: none;
    transition: border-bottom-color 0.3s ease; /* Add a smooth transition effect */
}

input:focus,
textarea:focus {
    border-bottom-color: #3498db; /* Change the underline color on focus */
}

input[type="submit"] {
    background-color: white;
    color: black;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border-radius: 1rem;
}

input[type="submit"]:hover {
    background-color: black;
    color: white;
    border-radius: 1rem;
}

.popup {
    background-color: #2ecc71;
    color: #fff;
    padding: 10px;
    border-radius: 5px;
    text-align: center;
    margin-top: 10px;
}

@media screen and (max-width: 600px) {
    .contacts{
        padding: 2rem;
    }
    form{
        padding: 1rem;
    }
    .contacts_header h1{
        font-size: 1.5rem;
    }
}